import { render, staticRenderFns } from "./FormRegister.vue?vue&type=template&id=7fe803c0&"
import script from "./FormRegister.vue?vue&type=script&lang=js&"
export * from "./FormRegister.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports